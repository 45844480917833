import React, { useState, useEffect , useRef } from "react";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Typography, TextField, Grid, Divider , CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import services from "../../../services";
import { Alert , TableContainer  , Table , TableHead , TableRow  , TableCell , TableBody} from "@mui/material";
import {
  changeUserId,
  currentUserId,
} from "../../../redux/reducers/manageUser";
import axios from "axios";
import constant from "../../../constant";
import api from "../../../api";
import { X_API_KEY } from "../../ENUM";
 
export default function BulkUpdateData() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
   
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState([]);
  const [errorDownload, setErrorDownload] = useState(false);
  const [logId, setLogId] = useState('');
  const [uomList, setUomList] = useState([]); 
  const [barcode, setBarcode] = useState('');

  const [successFile, setSuccessFile] = useState([]);
  const [successDownload , setSuccessDownload] = useState(false);
  let userId = useSelector(currentUserId);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const handleDownloadSample = () => {
    window.open(
      `${constant.IMG_BASE_URL}/filetemplate/filetemplate.csv`,
      "_blank"
    );
  };
  const getUserData = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_USER_DISCRIPTIONS(userId);
    if (response?.data?.ack == 1) {
      setFirstName(response?.data?.picker?.first_name);
      setLastName(response?.data?.picker?.last_name);
      setEmail(response?.data?.picker?.email);
      setRole(response?.data?.picker?.role);
    }
    dispatch(changeLoader(false));
  };
 
  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, []);

 
  
  
  const handleTypeChange = (e) => {
      
     
      setType(e.target.value);
    };
  
    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
     

    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Create form data
      setIsLoading(true); // Set loading state to true
      setShowAlert(false)
      setAlertMsg('');
      setSeverity("success")
      setErrorDownload(false);
      setSuccessDownload(false);
      const formData = new FormData();
      let typedata = '';

      if (type == 'inbound3') {
        typedata = 'inbound3';
      }
      if (type == 'inbound4') {
        typedata = 'inbound4';
      }
      if (type == 'inbound1' || type == 'inbound') {
        if (type == 'inbound') {
          typedata = 'inbound-p';
        } else {
          typedata = 'inbound-s';
        }
      
      }

      if (type == 'outbound' || type == 'outbound1'|| type == 'outbound2'|| type == 'outbound3'|| type == 'outbound4'|| type == 'outbound5'|| type == 'outbound6') {
    
        if (type == 'outbound') {
          typedata = 'outbound-p';
        } else if(type == 'outbound1') {
          typedata = 'outbound-d';
        } else if(type == 'outbound2') {
          typedata = 'outbound-e';
        }else if(type == 'outbound3') {
          typedata = 'outbound-pr';
        }else if(type == 'outbound4') {
          typedata = 'outbound-gf';
        }else if(type == 'outbound5') {
          typedata = 'outbound-ad';
        } else if (type == 'outbound6') {
          typedata = 'outbound-wa'
        }
      }


     
      formData.append("type", typedata);
      formData.append("csvFile", file);
  
    

      axios
      .post( constant.BASE_URL + api.Bulk.BulkUpdate, formData ,{
        headers: {
          "x-api-key": X_API_KEY, // Replace X_API_KEY with the actual value
        },
      })
      .then((response) => {
        console.log(response.data)
        response = response.data;
        setIsLoading(false); 
 


        if (response.status == 'fail') {
     
          setShowAlert(true)
          setAlertMsg(response.message);
          setSeverity("warning")
       
          setErrorFile(response.ReportData.UomStatus);
          setErrorDownload(true)
          setType("");
          setFile(null);
          formRef.current.reset();
          disableAlert()
          
        } else {
          setShowAlert(true)
          setAlertMsg('record update');
          setSeverity("success")
          setSuccessDownload(true)
          setSuccessFile(response.dataUpdate)
          setType("");
          setFile(null);
          disableAlert()
          formRef.current.reset();
        }

      })
      .catch((error) => {
        // Handle error
        // ...
        console.log("error", error);
        if (error.response.data.status == false) {
          setShowAlert(true)
          setAlertMsg(error.response.data.message);
          setSeverity("error")
          disableAlert()
          } 
        setIsLoading(false); // Set loading state to false on response

      });
    
    };
  
    const handlesuccessDownload = () => {
      const jsonData = successFile;
    
      // Create a CSV string from the JSON data
      const csvData = convertJsonToCsv(jsonData);
    
      // Create a Blob object with the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });
    
      // Create a URL for the Blob object
      const url = URL.createObjectURL(blob);
    
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.download = "upadate_record_report.csv";
    
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Simulate a click event on the link to trigger the download
      link.click();
    
      // Clean up by removing the link element and revoking the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };
  const handleDownload = () => {
    console.log(errorFile);
      const jsonData = errorFile;
    
      // Create a CSV string from the JSON data
      const csvData = convertJsonToCsv(jsonData);
    
      // Create a Blob object with the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });
    
      // Create a URL for the Blob object
      const url = URL.createObjectURL(blob);
    
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.download = "error_report.csv";
    
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Simulate a click event on the link to trigger the download
      link.click();
    
      // Clean up by removing the link element and revoking the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };
    
    const convertJsonToCsv = (jsonData) => {
      const header = Object.keys(jsonData[0]).join(",");
      const rows = jsonData.map((data) => Object.values(data).join(","));
      return [header, ...rows].join("\n");
    };
 
  
    const handleBarcodeChange = (event) => {
      setBarcode(event.target.value);
    };
  
    const handleBarcodeSubmit = async (event) => {
      event.preventDefault();
      try {
        
        const response = await axios.get( constant.BASE_URL + api.Bulk.getAllbarcode+barcode );
        const { success, message, data  , LogId} = response.data;
        if (success) {
          setUomList(data);
          setLogId(LogId);
          
        } else {
          console.log(message);
          setUomList([]);
          setAlertMsg(message);
          setSeverity("warning");
          setShowAlert(true);
          disableAlert()
        }
      } catch (error) {
        console.log('Error:', error);
        setAlertMsg(error.message);
        setSeverity("warning");
        setShowAlert(true);
        disableAlert()
        setUomList([]);
      }
    };
    const [editingRow, setEditingRow] = useState(null);

    const handleEdit = (rowId) => {
      setEditingRow(rowId);
    };
    
  const handleStockQuantityChange = (rowId, value) => {
    if (value == '') {
      value = 0;
    }
      setUomList((prevData) => {
        if (!prevData || prevData.length === 0) {
          return prevData;
        }
    
        let calculatedValue = 0
        let updatedData = prevData.map((uom) => {
          const updatedUom = {
            ...uom,
            stockQuantity: 0, // Set the stock quantity to 0 for all UOMs
          };
          return updatedUom;
        });

          updatedData = prevData.map((uom) => {
          if (uom.id === rowId) {
              calculatedValue = value * uom.relationWithBase;
            const updatedUom = {
              ...uom,
              stockQuantity: parseInt(value),
            };
            return updatedUom;
          }
          return uom;
        });
    
        const updatedUomList = updatedData.map((uomItem) => {
          if (uomItem.id !== rowId) {
            const updatedQuantity = parseInt(calculatedValue / uomItem.relationWithBase);
            return { ...uomItem, stockQuantity: updatedQuantity };
          }
          return uomItem;
        });
    
        return updatedUomList;
      });
    };
    
    
    
    const handleSave = () => {
      // Create a new array with the desired properties
      const newData = uomList.map((uom) => ({
        id: uom.id,
        stockQuantity: uom.stockQuantity,
        status : uom.stockQuantity > 0 ? 'active' :'inactive'
      }));
    
      // Create the formData object to send the newData
      
      // Make the API request using Axios
      axios
        .post(constant.BASE_URL + api.Bulk.BarcodeUpdate, {barcodeData:newData , LogId : logId}, {
          headers: {
            "x-api-key": X_API_KEY, // Replace X_API_KEY with the actual value
          },
        })
        .then((response) => {
          console.log(response.data);
          response = response.data;
          // setIsLoading(false); // Set loading state to false on response
          if (response.status === "fail") {
          
            
            setLogId(null);
            setEditingRow(null);
            setAlertMsg("UOM data not update.");
            setSeverity("warning");
            setShowAlert(true);
            disableAlert()
          } else if(response.status === "success") {
            
            setEditingRow(null);
            setAlertMsg("UOM data saved successfully.");
            setSeverity("success");
            setShowAlert(true);
            disableAlert()
           
          }
        })
        .catch((error) => {
          // Handle error
          if (error.response && error.response.data && error.response.data.status === false) {
            setShowAlert(true);
            setAlertMsg(error.response.data.message);
            setSeverity("error");
            disableAlert()
          }
          // setIsLoading(false); // Set loading state to false on response
        });
    
      // Clear the editing state and display a success message
    
    };
   
    return (
      <>
        <Box className="dashboard-parent">
          <Header />
          <Aside />
          <Box className="main-wrapper">
            <Box className="white-box-20-30">
              {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
              <div  style={{
                borderRadius: '10px',
                boxShadow: '1px 0px 5px -2px',
                padding: '30px',
                margin:'10px'
              }} >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6" gutterBottom>
                    Bulk Upload Form
                  </Typography>
                  <Button
                    
                    variant="contained"
                    color="success"
                    onClick={handleDownloadSample}
                    style={{ marginLeft: "auto" ,marginBottom:'10px' }}
                  >
                    InBound/OutBound Template  
                  </Button>
                  <br />
                </div>
                <form ref={formRef} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                          labelId="type-label"
                          id="type"
                          value={type}
                          onChange={handleTypeChange}
                          label="Type"
                        >
                          <MenuItem value="">
                            <em>Select type</em>
                          </MenuItem>
                          <MenuItem value="inbound">Purchase(inbound)</MenuItem>
                          <MenuItem value="inbound1">Sales Return(inbound)</MenuItem>
                          <MenuItem value="outbound">Purchase Return(outbound)</MenuItem>
                          <MenuItem value="outbound1">Demaged(outbound)</MenuItem>
                          <MenuItem value="outbound2">Expired(outbound)</MenuItem>
                          <MenuItem value="outbound3">Promotion(outbound)</MenuItem>
                          <MenuItem value="outbound4">Gift(outbound)</MenuItem>
                          <MenuItem value="outbound6">Waste(outbound)</MenuItem>
                          <MenuItem value="outbound5">Adjustment(outbound)</MenuItem>
                          <MenuItem value="inbound3">Price Update</MenuItem>
                          <MenuItem value="inbound4">Cost Price Update</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="file"
                        id="file"
                        // label="File"
                        onChange={handleFileChange}
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={isLoading}>
                    Submit
                  </Button>

               
                  {isLoading && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <CircularProgress size={24} />
                    </div>
                  )}
                  {errorDownload && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <Button variant="contained" onClick={handleDownload}>
                        Download Error Report
                      </Button>
                    </div>
                  )}
                  {successDownload && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <Button variant="contained" color="success" onClick={handlesuccessDownload}>
                        Download Stock Update Report
                      </Button>
                    </div>
                  )}
                </form>
              </div>
            </Box>
           
         
          </Box>
        </Box>
      </>
    );
    
}
