import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid, Button, Alert } from "@mui/material";
import services from "../../services";

// images import
import Logo from "../../assets/images/logo/logo.svg";
import { useReducer } from "react";

// initial state for user validation
const userInitialState = {
  email: "",
  password: "",
  emailError: "",
  passwordError: "",
  isValid: false,
};

function validUserReducer(state, action) {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_PASSWORD":
      return { ...state, password: action.payload };
      case "UPDATE_GOOGLE_AUTH_OTP":
      return { ...state, otp: action.payload };
    case "SET_EMAIL_ERROR":
      return { ...state, emailError: action.payload };
    case "SET_PASSWORD_ERROR":
      return { ...state, passwordError: action.payload };
    case "SET_USER_VALID":
      return { ...state, isValid: !state.isValid };
    
    default:
      return state;
  }
}
export const Login = () => {
  //states
  const [showPassword, setShowPassword] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const[loginText , setLoginText] = useState('Login')
  const [otpRegistrationShow, setOtpRegistrationShow] = useState(false);
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  const [userState, dispatch] = useReducer(validUserReducer, userInitialState);
  const navigate = useNavigate();

  // const routerReset = () => {
  //     const path = `/reset-password`;
  //     navigate(path);
  // };

  const ShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const emailChangeHandler = (e) => {
    dispatch({ type: "SET_EMAIL", payload: e.target.value });
  };

  const passwordChangeHandler = (e) => {
    dispatch({ type: "SET_PASSWORD", payload: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    var EMAIL_REGEX = /(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/i;

    if (userState.email === "") {
      dispatch({ type: "SET_EMAIL_ERROR", payload: "Please enter Email ID" });
      isValid = false;
    } else if (!EMAIL_REGEX.test(userState.email)) {
      dispatch({
        type: "SET_EMAIL_ERROR",
        payload: "Please enter a valid email address",
      });
      isValid = false;
    } else {
      dispatch({ type: "SET_EMAIL_ERROR", payload: "" });
    }
    const passwordValidation =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
    if (userState.password === "") {
      dispatch({
        type: "SET_PASSWORD_ERROR",
        payload: "Please enter password",
      });
      isValid = false;
    } else if (userState.password.length < 6) {
      dispatch({
        type: "SET_PASSWORD_ERROR",
        payload: "Password must be at least 6 characters long",
      });
      isValid = false;
    } else if (!passwordValidation.test(userState.password)) {
      dispatch({
        type: "SET_PASSWORD_ERROR",
        payload: "Please enter a valid password",
        // "Password should contain at least one lowercase, one uppercase letter, one number and one special character",
      });
      isValid = false;
    } else {
      dispatch({ type: "SET_PASSWORD_ERROR", payload: "" });
    }
    return isValid;
  };
  const loginHandler = async () => {
    const isValidCredentials = validateForm();
    if (localStorage.getItem("selectedMenu")) {
      localStorage.removeItem("selectedMenu");
    }
    if (isValidCredentials) {
      const data = {
        uname: userState.email,
        password: userState.password,
        role: "ims",
        Otp: userState.otp
      };
      // Login Api Call
      try {
        const response = await services.auth.LOGIN_USER(data);
      
        if (response && response.status === 200) {
          if (response.data.ack === 1) {
            console.log("Login successful");
            setToaster({
              status: "success",
              text: "Login successful",
              visibilty: true,
            });
            setTimeout(() => {
              window.location.href = "/bulk-BulkStockData";
            }, 700);
          } else if (response.data.ack === 0) {
            console.log("Incorrect Email or Password");
            let errorMessage = response.data.msg || "Incorrect Email or Password";
            setToaster({
              status: "error",
              text: errorMessage,
              visibilty: true,
            });
            setTimeout(() => {
              setToaster({ ...toaster, visibilty: false });
              navigate(`/`);
            }, 1500);
            if (response.data.auth === 1) {
              setOtpShow(true);
                setOtpRegistrationShow(true);
                  setLoginText('Continue')
            } else if (response.data.auth === 2) {
              setOtpShow(true);
                setLoginText('Continue')
             }
          }
        } else if (response && response.status === 201) {
          
          setToaster({
            status: "error",
            text:  response.data.msg || "Incorrect Email or Password",
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
            navigate(`/`);
          }, 700);
        }
      } catch (error) {
        console.log("Error in Login:", error);
      }
      
    } else {
      console.log("try more");
    }
  };

  // const loginHandler = async () => {

  //   // alert(JSON.stringify(userState));

 
  //   const isValidCredentials = validateForm();
  //   if (localStorage.getItem("selectedMenu")) {
  //     localStorage.removeItem("selectedMenu");
  //   }
  //   if (isValidCredentials) {
  //     const data = {
  //       uname: userState.email,
  //       password: userState.password,
  //       role: "ims",
  //       Otp: userState.otp

  //     };
  //     // Login Api Call
  //     try {
  //       const response = await services.auth.LOGIN_USER(data);
  //       if (response && response.status === 200) {
  //         setToaster({
  //           status: "success",
  //           text: "Login successfully",
  //           visibilty: true,
  //         });
  //         setTimeout(() => {
  //           navigate(`/bulk-upload`);
  //         }, 700);
  //       } else if (response && response.status === 201) {
  //         setToaster({
  //           status: "error",
  //           text:  response.data.msg,
  //           visibilty: true,
  //         });
  //         setTimeout(() => {
  //           setToaster({ ...toaster, visibilty: false });
  //           navigate(`/`);
  //         }, 1500);
  //       }
  //     } catch (error) {
  //       console.log("Error_In_Login", error);
  //     }
  //   } else {
  //     console.log("try more");
  //   }
  // };

  const registerToGoogleAuth = async () => {
    

    const isValidCredentials = validateForm();
    if (localStorage.getItem("selectedMenu")) {
      localStorage.removeItem("selectedMenu");
    }
    if (isValidCredentials) {
      const data = {
        uname: userState.email,
        password: userState.password,
        role: "ims",
        type:'registration'
     };
      // Login Api Call
      try {

        
        const response = await services.auth.LOGIN_USER(data);
        if (response.data.ack==1) {
          setToaster({
            status: "success",
            text: "QCode Send to your Email , please scan that in the google authenticator App and try Again login",
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
            navigate(`/`);
          }, 10000);
        } else if (response.data.ack==0) { 
          setToaster({
            status: "warning",
            text: response.data.msg,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
            navigate(`/`);
          }, 2500);
        }
      } catch (error) {
        console.log("Error_In_Login", error);
      }
    } else {
      console.log("try more");
    }
    
  }


  return (
    <>
      <div className="auth-bg">
        {toaster.visibilty && (
          <Alert severity={toaster.status}>{toaster.text}</Alert>
        )}
        <Box className="form-bg">

        <form  onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission
              loginHandler();
            }}>
            <img src={Logo} alt=".." className="logo-icon" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Email"
                  type="email"
                  id="email"
                  value={userState.email}
                  onKeyUp={(e) => (e.key === "Enter" ? loginHandler() : "")}
                  onChange={emailChangeHandler}
                />
                {userState.emailError && (
                  <div className="error">{userState.emailError}</div>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="baladiPassword">Password * </InputLabel>
                  <OutlinedInput
                    required
                    id="baladiPassword"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={userState.password}
                    onChange={passwordChangeHandler}
                    onKeyUp={(e) => (e.key === "Enter" ? loginHandler() : "")}
                  />
                  {userState.passwordError && (
                    <div className="error">{userState.passwordError}</div>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} textAlign="right" >                                
                                <Button size="small" variant="text" onClick={OpenForgot}>
                                    Forgot Password ?
                                </Button>
                            </Grid> */}
             {
                otpShow == true &&   <Grid    item xs={12}>
                <TextField
                  required
                  label="Google Authenticator OTP"
                  type="text"
                  id="googleAuthOTP"
                  value={userState.otp}
                  onChange={(e) => {
                    dispatch({ type: 'UPDATE_GOOGLE_AUTH_OTP', payload: e.target.value });
                    
                  }}
                  onKeyUp={(e) => (e.key === "Enter" ? loginHandler() : "")}
                />
               </Grid> 
               } 
      
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => loginHandler()}
                  sx={{ width: 1, marginTop: "10px" }}
                >
                  {loginText} {otpRegistrationShow} {otpShow}
                </Button>
              </Grid>
               {
                otpRegistrationShow == true &&   <Grid   item xs={12}>
                <Button
                  variant="contained"
                            size="small"
                            color="info"
                  onClick={registerToGoogleAuth}
                  sx={{ width: 1, marginTop: "10px" }}
                >
                  Register to Google Authenticator
                </Button>
              </Grid>
              } 
            </Grid>
          </form>


          {/* <form>
            <img src={Logo} alt=".." className="logo-icon" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Email"
                  type="email"
                  id="email"
                  value={userState.email}
                  onKeyUp={(e) => (e.key === "Enter" ? loginHandler() : "")}
                  onChange={emailChangeHandler}
                />
                {userState.emailError && (
                  <div className="error">{userState.emailError}</div>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="baladiPassword">Password * </InputLabel>
                  <OutlinedInput
                    required
                    id="baladiPassword"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={userState.password}
                    onChange={passwordChangeHandler}
                    onKeyUp={(e) => (e.key === "Enter" ? loginHandler() : "")}
                  />
                  {userState.passwordError && (
                    <div className="error">{userState.passwordError}</div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} textAlign="right" >                                
                                <Button size="small" variant="text" onClick={OpenForgot}>
                                    Forgot Password ?
                                </Button>
                            </Grid>  
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => loginHandler()}
                  sx={{ width: 1, marginTop: "10px" }}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form> */}

{/* <Box className="form-bg"> */}
  {/* <form>
    <img src={Logo} alt=".." className="logo-icon" />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          label="Email"
          type="email"
          id="email"
          value={userState.email}
          onKeyUp={(e) => (e.key === "Enter" ? loginHandler() : "")}
          onChange={emailChangeHandler}
        />
        {userState.emailError && (
          <div className="error">{userState.emailError}</div>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="baladiPassword">Password * </InputLabel>
          <OutlinedInput
            required
            id="baladiPassword"
            label="Password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={ShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            value={userState.password}
            onChange={passwordChangeHandler}
            onKeyUp={(e) => (e.key === "Enter" ? loginHandler() : "")}
          />
          {userState.passwordError && (
            <div className="error">{userState.passwordError}</div>
          )}
        </FormControl>
      </Grid>
    <Grid item xs={12}>
        <TextField
          required
          label="Google Authenticator OTP"
          type="text"
          id="googleAuthOTP"
          value={userState.otp}
          onChange={(e) => {
            dispatch({ type: 'UPDATE_GOOGLE_AUTH_OTP', payload: e.target.value });
          }}
        />
      </Grid>  
       
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          size="large"
          onClick={() => loginHandler()}
          sx={{ width: 1, marginTop: "10px" }}
        >
          Login
        </Button>
       </Grid>
          <Grid item xs={12}>
        <Button
          variant="contained"
                    size="small"
                    color="info"
          onClick={registerToGoogleAuth}
          sx={{ width: 1, marginTop: "10px" }}
        >
          Register to Google Authenticator
        </Button>
      </Grid> 
    </Grid>
  </form> */}
{/* </Box> */}

        </Box>
      </div>
      {/* <Dialog open={openForgot} onClose={handleClose}>
                <img src={modalClose} alt="..." onClick={handleClose} className='modal-close' />
                <DialogContent> 
                    <Typography variant="h6" component="h6" mb={3}>
                        Forgot Password
                    </Typography>
                    
                    <form>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <TextField required label="Email"   />
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Button onClick={routerReset} variant="contained" size="large" sx={{ width: 1, marginTop: '16px'}}>Save</Button>
                            </Grid>
                        </Grid>                        
                    </form>
                </DialogContent>
            </Dialog> */}
    </>
  );
};
