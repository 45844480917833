import React, { useState, useEffect , useRef } from "react";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
// import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Typography,   Grid, Divider, CircularProgress } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import {    Input } from '@mui/material';
 import TextField from '@mui/material/TextField';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import services from "../../../services";
import { Alert , TableContainer  , Table , TableHead , TableRow  , TableCell , TableBody} from "@mui/material";
import {
  changeUserId,
  currentUserId,
} from "../../../redux/reducers/manageUser";
import axios from "axios";
import constant from "../../../constant";
import api from "../../../api";
import { X_API_KEY } from "../../ENUM";
import Select from 'react-select';
export default  function BulkProductUpload() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
   
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState([]);
  const [errorDownload, setErrorDownload] = useState(false);
  const [logId, setLogId] = useState('');
  const [uomList, setUomList] = useState([]); 
  const [barcode, setBarcode] = useState('');
  const [lastIndex, setLastIndex] = useState(0);
  const [storeData, setStoreData] = useState([]);
  const [successFile, setSuccessFile] = useState([]);
  const [storeInfo, setStoreInfo] = useState([]);
  const [successDownload , setSuccessDownload] = useState(false);
  let userId = useSelector(currentUserId);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingstore, setIsLoadingstore] = useState(false);
  
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);
  
  const formRef = useRef(null);
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 30000);
  };
  const handleDownloadSample = () => {
    window.open(
      `${constant.IMG_BASE_URL}/filetemplate/productTemplate.csv`,
      "_blank"
    );
  };
    
    

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const convertJsonToCsv1 = (data) => {
    console.log("datssssa", data);
    const separator = ','; // You can change the separator as needed
    const header = ['storeId', 'brandId', 'itemCode', 'product name en', 'product name ar', 'uomName', 'unitLocalesName', 'uomId', 'Base Uom Id', 'barcode', 'uomCode', 'Uom price', 'Uom sale Price', 'stock', 'Uom stockQuantity', 'product stock Qunatity', 'Product Price', 'Product Sale Price'];
  
    const rows = data.flatMap((product) => {
      const uoms = product.uoms;
      const product_name = product.products_locales.find(locale => locale.locale === 'en')?.title || '';
      const product_name1 = product.products_locales.find(locale => locale.locale === 'ar')?.title || '';
  
      return uoms.map((uom) => {
        const unit = uom.unit?.unit_locales.find(locale => locale.locale === 'en')?.name || '';
        const barcode = uom.uom_barcode_relations[0]?.barcode || '';
        let productQuantity = '';
        let productprice = '';
        let productSalePrice = '';
        let baseUomId = '';
  
        if (uom.id === product.baseUomId) {
          productQuantity = product.stockQuantity;
          productprice = product.price;
          productSalePrice = product.salePrice;
          baseUomId = product.baseUomId;
        }
  
        return [
          product.storeId,
          product.brandId,
          product.itemCode,
          product_name1,
          product_name,
          uom.uomName || '',
          unit,
          uom.id || '',
          baseUomId,
          barcode,
          uom.UoMCode,
          uom.price,
          uom.salePrice,
          uom.stock,
          uom.stockQuantity,
          productQuantity,
          productprice,
          productSalePrice,
        ];
      });
    });
  
    const csvContent = [header, ...rows].map(row => row.join(separator)).join('\n');
    return csvContent;
  };
  
  // const convertJsonToCsv1 = (data) => {
  //   console.log("datssssa", data);
  //   const separator = ','; // You can change the separator as needed
  //    const header = ['storeId', 'itemCode', 'product name en', 'product name ar', 'uomName', 'unitLocalesName', 'uomId', 'Base Uom Id', 'barcode', 'uomCode', 'Uom price', 'Uom sale Price', 'stock', 'Uom stockQuantity', 'product stock Qunatity',
  //      'Product Price' , 'Product Sale Price'
  //    ];
  //    data.flatMap((store) => {
  //     const rows =store.products.map((product) => {
  //       const uoms = product.uoms;
  //       const product_name = product.products_locales[0].title;
  //       const product_name1 = product.products_locales[1]?.title;
 

  //       const uomRows = uoms.map((uom) => {
  //         const unit = uom.unit?.unit_locales[0]?.name || '';
  //         let productQuantity = '';
  //         let productprice = '';
  //         let productSalePrice = '';
  //         let baseUomId  = '';

         
  //         if (uom.id == product.baseUomId) {
  //             productQuantity = product.stockQuantity;
  //             productprice = product.price;
  //           productSalePrice = product.salePrice;
  //           baseUomId = product.baseUomId;
  //         }
         


  //         const price = uom.price;
  //         const stockQuantity = uom.stockQuantity;
  //         const stock = uom.stock;
  //         const uomCode = uom.UoMCode;
  //         const salePrice = uom.salePrice;
  //         const barcode = uom?.uom_barcode_relations[0]?.barcode
  //         return [
  //           store.id,
  //           product.itemCode,
  //           product_name1 , 
  //           product_name ,
  //           uom.uomName || '',
  //           unit,
  //           uom.id || '',
  //           baseUomId ,
  //           barcode || '',
  //           uomCode,
  //           price,
  //           salePrice,
  //           stock,
  //           stockQuantity,
  //           productQuantity,
  //           productprice, 
  //           productSalePrice,
  //         ];
  //       });
  //       return uomRows;
  //     });
  //   });
  
  //   const csvContent = [header, ...rows.flat()].map(row => row.join(separator)).join('\n');
  //   return csvContent;
  // };
  
  const handleDownloadstoreData = () => {
    const jsonData = storeInfo;
    
       console.log("jsonData" , jsonData);
    // Create a CSV string from the JSON data
    const csvData = convertJsonToCsv1(jsonData);
  
    // Create a Blob object with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });
  
    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);
  
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = `storeInfo.csv`;
  
    // Append the link to the document body
    document.body.appendChild(link);
  
    // Simulate a click event on the link to trigger the download
    link.click();
  
    // Clean up by removing the link element and revoking the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  //  const handleDownloadstoreData = () => {
  //   const jsonData = storeInfo;
    
  //    console.log("jsonData" , jsonData);
  //    return;
  //   // Create a CSV string from the JSON data
  //   const csvData = convertJsonToCsv(jsonData);
  
  //   // Create a Blob object with the CSV data
  //   const blob = new Blob([csvData], { type: 'text/csv' });
  
  //   // Create a URL for the Blob object
  //   const url = URL.createObjectURL(blob);
  
  //   // Create a temporary link element
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = `Categories_of_${selectedBusiness.label}.csv`;
  
  //   // Append the link to the document body
  //   document.body.appendChild(link);
  
  //   // Simulate a click event on the link to trigger the download
  //   link.click();
  
  //   // Clean up by removing the link element and revoking the URL
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };
  const getstoreDetailsdata = (storeId) => {
    setIsLoadingstore(true); // Set lo
    setStoreInfo([]);
    axios
      .post(constant.BASE_URL + api.Bulk.getStoreDetail, {
      storeId:storeId
    }, {
      headers: {
        "x-api-key": X_API_KEY, // Replace X_API_KEY with the actual value
      },
    })
    .then((response) => {
      const responseData = response.data.data;
    
      console.log("storeResponse", responseData);
      console.log("storeInfo.length", storeInfo.length);
    
      if (responseData.length === 0) {
        console.log("hello");
        // If responseData is empty, do nothing (optional)
      } else {
        // Push the new data from responseData into the storeInfo state
        setStoreInfo((prevStoreInfo) => [...prevStoreInfo, ...response.data.products_data]);
      }
    
      setIsLoadingstore(false); // Set loading state to false on response
    })
  
 
    
    .catch((error) => {
      setShowAlert(true);
      setSeverity("error");
      if (error.response) {
        setAlertMsg(error.response.data.message);
      } else if (error.message) {
        setAlertMsg(error.message);
      } else {
        setAlertMsg("Request failed");
      }
      disableAlert();
      setIsLoadingstore(false); // Set loading state to false on error
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    // Create form data

    setShowAlert(false)
    setAlertMsg('');
    setSeverity("success")
    setErrorDownload(false);
    setSuccessDownload(false);
    const formData = new FormData();
    
    console.log("selectedStore", selectedStore);
      formData.append("storeId", JSON.stringify(selectedStore));
    formData.append("csvFile", file);

    setIsLoading(true); // Set loading state to true

    axios
    .post(constant.BASE_URL + api.Bulk.bulkproduct, formData, {
      headers: {
        "x-api-key": X_API_KEY, // Replace X_API_KEY with the actual value
      },
    })
    .then((response) => {
      console.log(response.data);
      response = response.data;
      setIsLoading(false); // Set loading state to false on response
      if (response.status === 'fail') {
        setShowAlert(true);
        if (response.error) {
          setAlertMsg(`${response.message} - ${response.error}`);
        } else {
          setAlertMsg(response.message);
        }
        setSeverity("warning");
        setType("");
        setFile(null);
        formRef.current.reset();
        disableAlert();
      } else {
        setShowAlert(true);
        setAlertMsg(response.message);
        setSeverity("success");
        setType("");
        setFile(null);
        disableAlert();
        formRef.current.reset();
      }
    })
    .catch((error) => {
      setShowAlert(true);
      setSeverity("error");
      if (error.response) {
        setAlertMsg(error.response.data.message);
      } else if (error.message) {
        setAlertMsg(error.message);
      } else {
        setAlertMsg("Request failed");
      }
      disableAlert();
      setIsLoading(false); // Set loading state to false on error
    });
  
  
  };
 
    useEffect(() => {
      getStoreData();
      getBussinessType();
   }, []);
  const [bussinessOptions, setBussinessOptions] = useState({});
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const [categoryData , setCategoryData] = useState([]);
   const getBussinessType = async () => {
    try {
      const response = await axios.get(constant.BASE_URL + api.Bulk.GetBussinessType, {
        headers: {
          "x-api-key": X_API_KEY,
        },
      });
      const { data } = response.data;
        // setStoreOptions(data);
        const options = data.map((store) => ({
            value: store.id,
            label: `${store.id} - ${store.business_type_locales.find((localeData) => localeData.locale === 'en')?.name}`,
          }));
          setBussinessOptions(options);
    } catch (error) {
      console.log('Error:', error);
    }
  } 

  const [selectedStore, setSelectedStore] = useState([]);

  //  const [selectedStore, setSelectedStore] = useState({});
   const getStoreData = async () => {
    try {
      const response = await axios.get(constant.BASE_URL + api.Bulk.GetStoreData, {
        headers: {
          "x-api-key": X_API_KEY,
        },
      });
      const { data } = response.data;
        // setStoreOptions(data);
        const options = data.map((store) => ({
            value: store.id,
            label: `${store.id} - ${store.stores_locales.find((localeData) => localeData.locale === 'en')?.business_name}`,
          }));
          setStoreOptions(options);
    } catch (error) {
      console.log('Error:', error);
    }
  } 
  const getCategory = async (id) => {
    setIsLoadingCategory(true);
    setCategoryData([]);
    try { 
    
      const response = await axios.get(constant.BASE_URL + api.Bulk.GetAllCategories+id, {
        headers: {
          "x-api-key": X_API_KEY,
        },
      });
      const { data } = response.data;
        setCategoryData(data);
      setIsLoadingCategory(false);
    } catch (error) {
      console.log('Error:', error);
    }
  } 
  
  const [storeOptions, setStoreOptions] = useState([]);
  
  const handleBussinesChange = (selectedBusiness) => {
    console.log("selectedBusiness", selectedBusiness);
    setSelectedBusiness(selectedBusiness);
    getCategory(selectedBusiness.value);
   };
   const handleStoreChange = (selectedOption) => {
    console.log("selectedStore ", selectedStore);
    console.log("selectedOption", selectedOption);
  
    // Get the IDs of previously selected stores
    const prevSelectedStoreIds = selectedStore.map((store) => store.value);
  
    // Get the IDs of currently selected stores
    const currentSelectedStoreIds = selectedOption.map((store) => store.value);
  
    // Find the newly added stores (present in currentSelectedStoreIds but not in prevSelectedStoreIds)
    const addedStores = selectedOption.filter((store) => !prevSelectedStoreIds.includes(store.value));
  
    // Find the removed stores (present in prevSelectedStoreIds but not in currentSelectedStoreIds)
    const removedStores = selectedStore.filter((store) => !currentSelectedStoreIds.includes(store.value));
  
    // Perform actions based on added and removed stores
    if (addedStores.length > 0) {
      console.log("Added stores:", addedStores);
      getstoreDetailsdata(addedStores[0].value)
      // Perform additional actions for newly added stores if needed
    }
  
    if (removedStores.length > 0) {
      console.log("Removed stores:", removedStores);
      setStoreInfo(prevStoreInfo => prevStoreInfo.filter(item => item.id !== removedStores[0].value));

      // Perform additional actions for removed stores if needed
    }
  
     
    setSelectedStore(selectedOption);
   
  };
  
 
  const convertJsonToCsv = (jsonData) => {
    const headers = ['id', 'name', 'name_ar',   'categoryId' , 'Status' , 'Parent Id (0 Mean Its Parent Itself)'];
    const rows = [];
  
    jsonData.forEach((category) => {

      const rowData = [
        category.id,
        '',
        '',  
        '',
        category.parent_id == 0 ? 'Parent' : 'Child',
        category.parent_id 
      ];
  
      category.category_locales.forEach((locale) => {
        if (locale.locale === 'en') {
          rowData[1] = locale.name.replace(/,/g, ''); // Remove commas from English locale name
          rowData[3] = locale.categoryId;
        } else if (locale.locale === 'ar') {
          rowData[2] = locale.name.replace(/,/g, ''); // Remove commas from Arabic locale name
        }
      });
      
  
      rows.push(rowData);
    });
  
    const csvRows = rows.map((row) => row.join(','));
    return [headers.join(','), ...csvRows].join('\n');
  };
  
  const handleDownloadCategories = () => {
    const jsonData = categoryData;
    
    // Create a CSV string from the JSON data
    const csvData = convertJsonToCsv(jsonData);
  
    // Create a Blob object with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });
  
    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);
  
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = `Categories_of_${selectedBusiness.label}.csv`;
  
    // Append the link to the document body
    document.body.appendChild(link);
  
    // Simulate a click event on the link to trigger the download
    link.click();
  
    // Clean up by removing the link element and revoking the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  
  
 
    return (
      <>
        <Box className="dashboard-parent">
          <Header />
          <Aside />
          <Box className="main-wrapper">
          <Typography variant="h5" gutterBottom>
                  
            </Typography>
 
            
            <Box  style={{
                borderRadius: '5px',
                boxShadow: '1px 0px 5px -2px',
                padding: '30px',
                margin:'10px'
              }}>
            <h4> 
              Product Upload
              </h4>
             
            <Box style={{
                borderRadius: '10px',
                boxShadow: '1px 0px 5px -2px',
                padding: '30px',
                margin:'10px'
              }}  className="white-box-20-30">
             
              <br />
             
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel shrink={true} htmlFor="business-type-select">
                    Search Business Type
                  </InputLabel>
                  <Select
                    value={selectedBusiness}
                    onChange={handleBussinesChange}
                    options={bussinessOptions}
                    placeholder="Search Business Type"
                    isSearchable
                  />
                </Grid>
                <Grid item xs={6}>
                  {categoryData.length > 0 && (
                    <Button
                      style={{ marginTop: 21 }}
                      variant="contained"
                      color="primary"
                      onClick={handleDownloadCategories}
                    >
                      Download Categories
                    </Button>
                  )}
                </Grid>
                {isLoadingCategory && (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <CircularProgress size={24} />
                  </div>
                )}
              </Grid>
            </Box>

            <Box style={{
                borderRadius: '10px',
                boxShadow: '1px 0px 5px -2px',
                padding: '30px',
                margin:'10px'
              }}  className="white-box-20-30">
              {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
              <div>
             
                <div style={{ display: "flex", alignItems: "center" }}>
              
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleDownloadSample}
                    style={{ marginLeft: "auto" ,marginBottom : '10px' }}
                  >
                    New Product Upload Template
                  </Button>
                </div>
                <form ref={formRef} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                  
                    <Grid item xs={6}>
                    <InputLabel shrink={true} htmlFor="business-type-select">
                      Search Store  
                    </InputLabel>
                        <Select
                        
                            value={selectedStore}
                            onChange={handleStoreChange}
                            options={storeOptions}
                            placeholder="Search store"
                            isSearchable
                            isMulti
                        
                      />
                       {isLoadingstore && (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <CircularProgress size={24} />
                  </div>
                      )}
                      {storeInfo.length > 0 && (
                    <Button
                      style={{ marginTop: 21 }}
                      variant="contained"
                      color="primary"
                      onClick={handleDownloadstoreData}
                    >
                      Download Store Data
                    </Button>
                  )}
                        </Grid>
                    <Grid item xs={6}>
                      <TextField
                        style={{ marginTop: 4 }}
                        fullWidth
                        type="file"
                        id="file"
                        // label="File"
                        onChange={handleFileChange}
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                    Submit
                  </Button>
                  {isLoading && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <CircularProgress size={24} />
                    </div>
                  )}
                  
                </form>
              </div>
            </Box>
         
         
            </Box>
          </Box>
        </Box>
      </>
    );
    
}
