import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Grid,
  Divider,
  CircularProgress,
  Pagination,
  Collapse,
  Box,
  TextField,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import services from "../../../services";
import { currentUserId } from "../../../redux/reducers/manageUser";
import axios from "axios";
import constant from "../../../constant";
import api from "../../../api";
import { X_API_KEY } from "../../ENUM";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";

export default function BulkLedger() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  let userId = useSelector(currentUserId);

  const getUserData = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_USER_DISCRIPTIONS(userId);
    if (response?.data?.ack == 1) {
      setFirstName(response?.data?.picker?.first_name);
      setLastName(response?.data?.picker?.last_name);
      setEmail(response?.data?.picker?.email);
      setRole(response?.data?.picker?.role);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchProducts(value, searchTerm, limit);
  };

  const downloadLogs = (name, log) => {
    console.log("Object", log);
    // Convert object keys to CSV headers
    const headers = Object.keys(log[0]).join(',') + '\n';
  
    // Convert object values to CSV rows
    const rows = log.map(row =>
      Object.values(row).join(',')
    ).join('\n');
  
    // Combine headers and rows
    const csvContent = headers + rows;
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Create a download link and trigger it
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', name+'.csv');
    document.body.appendChild(link);
    link.click();
  
    // Clean up by removing the link
    document.body.removeChild(link);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1); // Reset to first page when changing limit
    fetchProducts(1, searchTerm, e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to first page when searching
    fetchProducts(1, e.target.value, limit);
  };

  const fetchProducts = (page, searchTerm = "", limit = 10) => {
    setShowAlert(false);
    setAlertMsg("");
    setSeverity("success");

    const queryParams = new URLSearchParams({
      startDate: startDate,
      endDate: endDate,
      page: page,
      limit: limit,
      searchTerm: searchTerm,
    }).toString();

    setIsLoading(true);

    axios
      .get(`${constant.BASE_URL}/bulk/get_stock_data?${queryParams}`, {
        headers: {
          "x-api-key": X_API_KEY,
        },
      })
      .then((response) => {
        try {
          const fetchedProducts = response.data.products.map((product) => {
            // Calculate Purchase Qty and Purchase Value
            console.log("product", product);
             
            if (product) {
              // const purchaseQty = product.ledger_data.inbound ;
              // const purchaseValue = purchaseQty * product.price;
              
              // const saleQty = product.ledger_data.saledata ;
              // const saleQtyValue = saleQty * product.price;
    
              // const ledgerData = product.ledger_data;
    
              // const ledgerLog = product.ledger_logs;
              // ledger_logs
    
              return {
                ...product,
                
                
              };
            }
           
          });
          setProducts(fetchedProducts);
          setTotalPages(response.data.totalPages);
          setIsLoading(false);
        } catch (ex) {
            console.log("ex" , ex)
          }
      })
      .catch((error) => {
        console.error("Error:", error);
        setAlertMsg("Failed to fetch data.");
        setSeverity("error");
        setShowAlert(true);
        setIsLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPage(1);
    fetchProducts(1, searchTerm, limit);
  };

  const handleDownload = () => {
    const csvContent = [
      [
        "Start Date",
        "End Date",
        "Product ID",
        "Item Code",
        "Slug",
        "Category",
        "Category Parent",
        "Parent Category",
        "UOM ID",
        "Stock Quantity",
        "Price",
        "Cost Price",
        "Retail Price",
        "Barcode",
        "Opening Stock Qty",
        "Opening Stock Value",
        "Purchase Qty",
        "Purchase Value",
        "Sale Qty",
        "Sale Value",
        "Closing Stock Qty",
        "Closing Stock Value",
       
        "Outbound",
       
        "Return Data" 
        
       
      ],
      ...products.map((product) => [

        startDate,
        endDate,
        product?.productId,
        product?.itemCode,
        product?.slug.split(',')[0],
        product?.sub_sub_category?.split(',')[0],
        product?.sub_category?.split(',')[0],
        product?.category_name?.split(',')[0],
      
        product?.uomId,
        product.stockQuantity,
        product?.price,
        product?.costPrice,
        product?.retailPrice,
        product?.barcode,

        product?.openingStockQty,
        product?.openingStockValue,

        product?.purchaseqty,
        product?.purchaseValue,

        product?.saleqty,
        product?.saleqtyvalue,
 
        product?.closingStockQty,
        product?.closingStockValue,

          product.outbound,
     
        product.returnentry,
        

      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "products_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "1px 0px 5px -2px",
                padding: "30px",
                margin: "10px",
              }}
            >
              <form ref={formRef} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Start Date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      type="date"
                      label="End Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      select
                      label="Limit"
                      value={limit}
                      onChange={handleLimitChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {[10 , 1000 , 5000  ,7000,  10000 , 30000 , 50000 ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid> */}
                </Grid>
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  sx={{ mt: 2, ml: 2 }}
                  onClick={handleDownload}
                >
                  Download CSV
                </Button>

                {isLoading && (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <CircularProgress size={24} />
                  </div>
                )}

                {!isLoading && products.length > 0 && (
                  <>
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      sx={{ mt: 2 }}
                    />
                    <div style={{ marginTop: "20px" }}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Product ID</TableCell>
                              <TableCell>Item Code</TableCell>
                              <TableCell>Slug</TableCell>
                              <TableCell>UOM Data</TableCell>
                              <TableCell>Category</TableCell>
                                <TableCell>Sub Category</TableCell>
                                <TableCell>Parent Category</TableCell>
                              <TableCell>Current Stock Quantity</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Cost Price</TableCell>
                              <TableCell>Retail Price</TableCell>
                              <TableCell>Barcode</TableCell>
                              <TableCell>Opening Stock Qty</TableCell>
                              <TableCell>Opening Stock Value</TableCell>
                              
                              <TableCell>Purchase Qty</TableCell>
                              <TableCell>Purchase Value</TableCell>
                              <TableCell>Sale Qty</TableCell>
                              <TableCell>Sale Value</TableCell>

                              <TableCell>Closing Stock Qty</TableCell>
                              <TableCell>Closing Stock Value</TableCell>

                              {/* <TableCell>Inbound</TableCell> */}
                              <TableCell>Out Bound</TableCell>
                              {/* <TableCell>Sales</TableCell> */}
                              <TableCell>Return</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {products.map((product) => (
                             
                              <TableRow key={product?.productId }>
                                <TableCell>{product?.productId}</TableCell>
                                <TableCell>{product?.itemCode}</TableCell>
                                <TableCell>{product?.slug}</TableCell>
                                <TableCell>{`${product?.uomName} (ID: ${product?.uomId})`}</TableCell>
                                 <TableCell>{product?.sub_sub_category}</TableCell>
                                <TableCell>{product?.sub_category}</TableCell>
                                <TableCell>{product?.category_name}</TableCell>
                                <TableCell>{product?.stockQuantity }</TableCell>
                                <TableCell>{product?.price }</TableCell>
                                <TableCell>{product?.costPrice }</TableCell>
                                <TableCell>{product?.retailPrice }</TableCell>
                                <TableCell>{product?.barcode}</TableCell>
                                <TableCell>{product?.openingStockQty}</TableCell>
                                <TableCell>{product?.openingStockValue }</TableCell>
                                <TableCell>{product?.purchaseqty}</TableCell>
                                <TableCell>{product?.purchaseValue }</TableCell>
                                <TableCell>{product?.saleqty}</TableCell>
                                <TableCell>{product?.saleqtyvalue }</TableCell>
                                <TableCell>{product?.closingStockQty}</TableCell>
                                <TableCell>{product?.closingStockValue }</TableCell>
                                <TableCell>{product?.outbound}</TableCell>
                                <TableCell>{product?.returnentry}</TableCell>
                                
                                <TableCell>  <Button  variant="contained"   onClick={() => {
                                  downloadLogs(product?.itemCode , product?.ledger_logs )
                                }}>   Download    </Button>
                              </TableCell>
                            </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
              </form>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}
