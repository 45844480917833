import React, { useState, useEffect , useRef } from "react";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Typography, TextField, Grid, Divider , CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import services from "../../../services";
import { Alert , TableContainer  , Table , TableHead , TableRow  , TableCell , TableBody} from "@mui/material";
import {
  changeUserId,
  currentUserId,
} from "../../../redux/reducers/manageUser";
import axios from "axios";
import constant from "../../../constant";
import api from "../../../api";
import { X_API_KEY } from "../../ENUM";
 
export default function Bulkaddons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
   
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState([]);
  const [errorDownload, setErrorDownload] = useState(false);
  const [logId, setLogId] = useState('');
  const [uomList, setUomList] = useState([]); 
  const [barcode, setBarcode] = useState('');

  const [successFile, setSuccessFile] = useState([]);
  const [successDownload , setSuccessDownload] = useState(false);
  let userId = useSelector(currentUserId);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const handleDownloadSample = () => {
    window.open(
      `${constant.IMG_BASE_URL}/filetemplate/addonstemplate.csv`,
      "_blank"
    );
  };
  const getUserData = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_USER_DISCRIPTIONS(userId);
    if (response?.data?.ack == 1) {
      setFirstName(response?.data?.picker?.first_name);
      setLastName(response?.data?.picker?.last_name);
      setEmail(response?.data?.picker?.email);
      setRole(response?.data?.picker?.role);
    }
    dispatch(changeLoader(false));
  };
 
  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, []);
  const handleFileChange = (e) => {
      setFile(e.target.files[0]);
     

    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Create form data

      setShowAlert(false)
      setAlertMsg('');
      setSeverity("success")
      setErrorDownload(false);
      setSuccessDownload(false);
      const formData = new FormData();
      formData.append("csvFile", file);
  
      setIsLoading(true); // Set loading state to true

      axios
      .post( constant.BASE_URL + api.Bulk.bulkaddons, formData ,{
        headers: {
          "x-api-key": X_API_KEY, // Replace X_API_KEY with the actual value
        },
      })
      .then((response) => {
        console.log(response.data)
        response = response.data;
        setIsLoading(false); // Set loading state to false on response
 

        if (response.success == 'fail' || response.status == 'fail') {
          setShowAlert(true)
          setAlertMsg(response.message);
          setSeverity("warning")
          setType("");
          setFile(null);
          formRef.current.reset();
          disableAlert()
        } else {
          setShowAlert(true)
          setAlertMsg('record update');
          setSeverity("success")

          setType("");
          setFile(null);
          disableAlert()
          formRef.current.reset();
        }

      })
      .catch((error) => {
        // Handle error
        // ...
        console.log("error", error);
        if (error.response.data.status == false) {
          setShowAlert(true)
          setAlertMsg(error.response.data.message);
          setSeverity("error")
          disableAlert()
          } 
        setIsLoading(false); // Set loading state to false on response

      });
    
    };
    
      return (
      <>
        <Box className="dashboard-parent">
          <Header />
          <Aside />
          <Box className="main-wrapper">
            <Box className="white-box-20-30">
              {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
              <div  style={{
                borderRadius: '10px',
                boxShadow: '1px 0px 5px -2px',
                padding: '30px',
                margin:'10px'
              }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6" gutterBottom>
                    Bulk Addons Form
                  </Typography>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleDownloadSample}
                    style={{ marginLeft: "auto" }}
                  >
                   Addon Template
                  </Button>
                </div>
                <form ref={formRef} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="file"
                        id="file"
                        // label="File"
                        onChange={handleFileChange}
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                    Submit
                  </Button>
                  {isLoading && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <CircularProgress size={24} />
                    </div>
                  )}
                  {/* {errorDownload && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <Button variant="contained" onClick={handleDownload}>
                        Download Error Report
                      </Button>
                    </div>
                  )}
                  {successDownload && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <Button variant="contained" color="success" onClick={handlesuccessDownload}>
                        Download Stock Update Report
                      </Button>
                    </div>
                  )} */}
                </form>
              </div>
            </Box>
           
         
          </Box>
        </Box>
      </>
    );
    
}
