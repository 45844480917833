export default {
 
  BulkUpdate: `/bulk/updatestock_new`,
  getAllbarcode: '/bulk/get_all_uoms_barcode/',
  BarcodeUpdate: '/bulk/updateBarcodeData',
  GetStoreData: '/bulk/GetStoreData',
  bulkproduct: '/bulk/bulkStoreProduct_v1',
  update_date: '/bulk/update_date/',
  bulkstore: '/bulk/bulkStore',
  bulkaddons: '/bulk/bulkaddons',
  bulkledger: '/bulk/get_stock_data',
   
  getStoreDetail: '/bulk/getStoreDetail',
  GetBussinessType: '/bulk/GetBussinessType',
  GetAllCategories: '/bulk/GetAllCategories/',
  bulk_store_category_relation: '/bulk/bulk_store_category_relation/',
  
  };
  