import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Grid,
  Divider,
  CircularProgress,
  Pagination,
  Collapse,
  Box,
  TextField,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import services from "../../../services";
import { currentUserId } from "../../../redux/reducers/manageUser";
import axios from "axios";
import constant from "../../../constant";
import api from "../../../api";
import { X_API_KEY } from "../../ENUM";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";

export default function BulkStockData() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alreadyUpdated, setAlreadyUpdated] = useState(0);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  let userId = useSelector(currentUserId);
  const [scanBarcode, setScanBarcode] = useState("");
  let [enterValue, setEnterValue] = useState("");
  let [incType, setIncType] = useState("");
  
  
  const [uomList, setUomList] = useState([]); 
  const [editingRow, setEditingRow] = useState(null);

  const handleEdit = (rowId) => {
    setEditingRow(rowId);
  };
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const [proList, setProList] = useState([]);
  const [scanUomData, setScanUomData] = useState([]);
  const [logData, setLogData] = useState([]);
  
  const getUserData = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_USER_DISCRIPTIONS(userId);
    if (response?.data?.ack == 1) {
      setFirstName(response?.data?.picker?.first_name);
      setLastName(response?.data?.picker?.last_name);
      setEmail(response?.data?.picker?.email);
      setRole(response?.data?.picker?.role);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, []);


  const fetchProducts_data = () => {
    fetchProducts(scanBarcode);
  
  };
  const handScanBarcode = (e) => {
    setScanBarcode(e.target.value);
  
  };

  const StockAdding = () => {



    let uom = uomList.find(uom => uom.relationWithBase === 1);
    let uomDataArray = uomList.map(uom => ({
      id: uom.id, // UOM id
      stockQuantity: uom.stockQuantity // UOM stockQuantity
    }));
        let data = {
          productId: proList.id,
          BaseUom: proList.baseUomId,
          stockQuantity: uom ? uom.stockQuantity : 0 , // Use null or another default value if uom is not found
          uomData: uomDataArray,
          ledger: {
            documentType: 'stock-adjustment-inbound',
            qty_inward: uom ? uom.stockQuantity  : 0,
            balance: uom ? uom.stockQuantity : 0,
            confirm: 1,
            productId: proList.id,
            uomId :proList.baseUomId,
            
          }
        };

    console.log("data"+ JSON.stringify(proList));
    axios.post(`${constant.BASE_URL}/bulk/stock_inbound_for_stock_take`, {
      data:data
    }  , {
      headers: {
        "x-api-key": X_API_KEY,
      },
    })
    .then((response) => {
     


      if (response.data.status == 'fail') {
        setAlertMsg( response.data.message);
        setSeverity("error");
        setShowAlert(true);
       setIsLoading(false);
       disableAlert()
        return;
      }
      setUomList("");
     
      setScanBarcode('');
      setEnterValue('')
      setUomList("");
      setProList("");
      // setScanBarcode("");
      go_back();
      setScanUomData("");
      setAlertMsg( response.data.message);
      setSeverity("success");
      setShowAlert(true);
      setIsLoading(false);
      disableAlert()
    })
    .catch((error) => {
       
      setAlertMsg("Please Not Refreshed.");
      setSeverity("error");
      setShowAlert(true);
      setIsLoading(false);
    });

  }
  const refreshBarcode = () => {
    axios.get(`${constant.BASE_URL}/bulk/refresh_item_code/${scanBarcode}`, {
      headers: {
        "x-api-key": X_API_KEY,
      },
    })
    .then((response) => {
     
      console.log("resopinse", response);
     
      setAlertMsg(response.data.message);
      setSeverity("success");
      if (response.data.message == "Product already Refreshed" || response.data.message == "Product Refreshed , Please Count and Update Stock") {
        setAlreadyUpdated(1);
        setUomList(uomList.map(uom => ({
          ...uom,
          stockQuantity: 0 // Set stockQuantity to zero
        })));
        setOriginalUomList(uomList.map(uom => ({
          ...uom,
          stockQuantity: 0 // Set stockQuantity to zero
        })));
        
      }
      
      setShowAlert(true);
      setIsLoading(false);
      disableAlert()
    })
    .catch((error) => {
       
      setAlertMsg("Product Not Refreshed.");
      setSeverity("error");
      setShowAlert(true);
      setIsLoading(false);
    });
  }

  const [originalUomList, setOriginalUomList] = useState([]);
const [enteredValue, setEnteredValue] = useState(0);

// Store original values on initial load or when uomList changes
useEffect(() => {
  // Save a deep copy of the uomList to preserve all properties
  // setOriginalUomList(uomList.map(uom => ({ ...uom })));
}, [uomList]);

  const handle_enter_value = (value) => {
     
  // setEnteredValue(value);
      console.log(value)
  // Calculate the updated stock quantity based on the entered value
   
    // console.log("uomList "  , JSON.stringify(uomList))
    // const baseUom = uomList.find(uom => uom.relationWithBase === 1);
  
    let updatedStock = value * scanUomData.relationWithBase;
    // let updatedStock = value;
console.log("scanUomData.relationWithBase,",scanUomData.relationWithBase)
    if (value == 0) {
    
      console.log("ssss");
    // Revert to the original stock quantities if the entered value is zero
      
        console.log("originalUomList", originalUomList);
    
        setUomList(originalUomList);
      
  } else {
    // Update the stock quantity for each UOM in the uomList
    const updatedUomList = uomList.map((uom) => {
      // Calculate the new stock quantity
      const newStockQuantity = updatedStock / uom.relationWithBase;
      console.log("newStockQuantity", newStockQuantity);
    
      // Check if the scanned barcode ID matches the current UOM ID
      if (scanUomData.id === uom.id) {
        // If the IDs match, replace the stockQuantity with newStockQuantity
        return {
          ...uom,
          stockQuantity: parseInt(newStockQuantity),
        };
      } else {
        // If the IDs don't match, update the stockQuantity based on incType
        if (incType === 'plus') {
          return {
            ...uom,
            stockQuantity:   parseInt(newStockQuantity),
          };
        } else {
          return {
            ...uom,
            stockQuantity:   parseInt(newStockQuantity),
          };
        }
      }
    });
    
    // Update the uomList state with the new stock quantities
    setUomList(updatedUomList);
  }
};
  // const handle_enter_value = (value) => {
  //   const enteredValue = value;
  // setEnterValue(enteredValue);

  // // Calculate the updated stock quantity based on the entered value and the relationWithBase
  // let updatedStock = enteredValue * scanUomData.relationWithBase;

  // // Update the stock quantity for each UOM in the uomList
  // const updatedUomList = uomList.map((uom) => {
  //   // Calculate the new stock quantity
  //   const newStockQuantity = updatedStock / uom.relationWithBase;

  //   return {
  //     ...uom,
  //     stockQuantity: (uom.stockQuantity || 0) + parseInt(newStockQuantity),
  //   };
  // });

  // // Update the uomList state with the new stock quantities
  // setUomList(updatedUomList);
  
  //   // Update the scanUomData's stockQuantity with the enteredValue
  //   // setScanUomData((prevData) => ({
  //   //   ...prevData,
  //   //   stockQuantity: parseInt(enteredValue),
  //   // }));
  // };
  
  const [manufactureDate, setManufactureDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const fetchProducts = (scannedBarced) => {
    setShowAlert(false);
    setAlertMsg("");
    setSeverity("success");

    const queryParams = new URLSearchParams({
      startDate: startDate,
      endDate: endDate,
      page: page,
      limit: limit,
      searchTerm: searchTerm,
    }).toString();

    setIsLoading(true);
   
    
    axios
      .get(`${constant.BASE_URL}/bulk/get_all_uoms_barcode_scan/${scanBarcode}`, {
        headers: {
          "x-api-key": X_API_KEY,
        },
      })
      .then((response) => {
        const { success, message, data  ,scanbarcodeobj, products, LogId , alreadyUpdate , stockdata_log} = response.data;
        if (success) {
          setUomList(data);
          setOriginalUomList(data.map(uom => ({ ...uom })));
          setScanUomData(scanbarcodeobj)
          setManufactureDate( data[0].product_manufacture);
          setExpiryDate( data[0].product_expiry);
          setIsLoading(false);

          setLogData(stockdata_log);
          if (alreadyUpdate == true) {
            setAlreadyUpdated(1);
              }
          setProList(products);
        } else {
          console.log(message);
          setScanUomData([])
          setUomList([]);
          setAlertMsg(message);
          setSeverity("warning");
          setShowAlert(true);
          setIsLoading(false);
          disableAlert()
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setAlertMsg("Failed to fetch data.");
        setSeverity("error");
        setShowAlert(true);
        setIsLoading(false);
      });
  };

  const go_back = () => {
    
    axios
    .get(`${constant.BASE_URL}/bulk/make_it_live/${scanBarcode}`, {
      headers: {
        "x-api-key": X_API_KEY,
      },
    })
    .then((response) => {
      const { success, message} = response.data;
      if (success) {
        setUomList([]);
        setOriginalUomList([]);
        setScanUomData([])
        setScanBarcode('')
        setManufactureDate([]);
        setExpiryDate([]);
        setIsLoading(false);
        setAlreadyUpdated(0);
       
        setProList([]);
        setAlertMsg(message);
        setSeverity("success");
        setShowAlert(true);
        setIsLoading(false);
        disableAlert()
      } else {
       
         setAlertMsg(message);
        setSeverity("warning");
        setShowAlert(true);
        setIsLoading(false);
        disableAlert()
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      setAlertMsg("Failed to fetch data.");
      setSeverity("error");
      setShowAlert(true);
      setIsLoading(false);
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setPage(1);
    fetchProducts(1, searchTerm, limit);
  };

     useEffect(() => {
       handle_enter_value(enterValue);
      },[enterValue])

      // const handle_enter_value = (newValue) => {
      //   setEnterValue(newValue);
      // };
    
      const incrementValue = () => {
          setIncType('plus')
        setEnterValue((prevValue) => Math.max(0, prevValue + 1));
      };
    
  const decrementValue = () => {
    setIncType('minus')
        setEnterValue((prevValue) => Math.max(0, prevValue - 1));
      };
     
  return (
    <>
      
      <style>
        {`
          .header-wrapper {
            position: relative; /* Specify your desired position here */
            
          }
        `}
      </style>
    <Box>
        <Header />
    </Box> 
 
      
    
      <Box className="dashboard" sx={{ padding: 2 }}>
      {alreadyUpdated === 0 && proList.length !== 0 && (
  <Button
    variant="contained"
    size="large"
    sx={{
      width: '100%',
      mb: 2,
    }}
    onClick={refreshBarcode}
  >
    Refresh Product
  </Button>
        )}
           { proList.length != 0 && (
        <Button
          variant="contained"
          size="large"
          sx={{
            width: '100%',
            mb: 2,
          }}
          onClick={go_back}
        >
          Go Back
        </Button>
        )}
        
  <Box
    className="main-wrapper"
    sx={{
      maxWidth: '600px',
      mx: 'auto',
      p: 2,
      boxShadow: 1,
      borderRadius: 2,
      backgroundColor: 'background.paper',
    }}
  >
    {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}

          {
            proList.length  == 0 && (<>
            <form ref={formRef} onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            label="Scan Barcode"
            value={scanBarcode}
            onChange={handScanBarcode}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                fetchProducts_data(scanBarcode);
              }
            }}
             />
              </Grid>
             {
              scanBarcode && <>
              <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="large"
                sx={{ width: '100%' }}
                onClick={() => fetchProducts_data(scanBarcode)}
              >
                Scan
              </Button>
              </Grid>
            </>
              } 
       
      </Grid>
    </form>
            </>)
          }
    
          {
            proList.length != 0 && (
              <>
               <Box sx={{ mt: 2 }}>
      <TextField
        fullWidth
        type="number"
        label="Enter Stock Quantity"
        value={enterValue}
        onChange={(e) => {
          const newValue = Math.max(0, Number(e.target.value)); // Ensure the value is non-negative
          // handle_enter_value(newValue);
          setEnterValue(newValue);
        }}
        inputProps={{ min: 0 }} // Set minimum value to 0
      />

      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            size="large"
            sx={{ width: '100%' }}
            onClick={incrementValue}
          >
            +
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            size="large"
            sx={{ width: '100%' }}
            onClick={decrementValue}
          >
            -
          </Button>
        </Grid>
      </Grid>

      {enterValue !== '' && enterValue > 0 && (
        <Button
          variant="contained"
          size="large"
          sx={{ width: '100%', mt: 1 }}
          onClick={StockAdding}
        >
          Add Stock
        </Button>
      )}
    </Box></>
              )
          }
   
    {/* <Box sx={{ mt: 2 }}>
    <TextField
      fullWidth
      type="number"
      label="Enter Stock Quantity"
      value={enterValue}
      onChange={(e) => {
        const newValue = Math.max(0, e.target.value); // Ensure the value is non-negative
        // setEnterValue(newValue);
        handle_enter_value(newValue);
      }}
      inputProps={{ min: 0 }} // Set minimum value to 0
    />

      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            size="large"
            sx={{ width: '100%' }}
                  onClick={() => {
              //       alert("enterValue" + enterValue)
              if (enterValue < -1) {
                return
               }
              setEnterValue(enterValue++);
              // handle_enter_value(enterValue++);
            }}
          >
            +
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            size="large"
            sx={{ width: '100%' }}
                  onClick={() => {
                    // alert("enterValue" + enterValue)
                    if (enterValue < 0) {
                      return
                    }
                    // setEnterValue(enterValue--);
                    // alert(enterValue);
              setEnterValue(enterValue--);
             
            }}
          >
            -
          </Button>
              </Grid>
             
      </Grid>
            {
              enterValue != '' && <>
               <Button
              variant="contained"
              size="large"
              sx={{ width: '100%', mt: 1 }}
              onClick={StockAdding}
            >
              Add Stock
            </Button>
              </>
            }
     
    </Box> */}

    {uomList.length > 0 && (
      <Box sx={{ mt: 2 }}>
        {isLoading && (
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <CircularProgress size={20} />
          </Box>
        )}
              {uomList && uomList.length > 0 && <>
                <Grid container spacing={1}>
          
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: 2,
                boxShadow: 1,
                p: 2,
                backgroundColor: 'background.paper',
                height: '100%',
              }}
            >
            <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold', mb: 2 }}>
            <Box
              sx={{
                display: 'flex', 
                alignItems: 'center', // Align items vertically center
                justifyContent: 'space-between', // Space between text and image
                borderRadius: 2,
                boxShadow: 1,
                p: 1,
                backgroundColor: 'background.paper',
                height: '100%',
              }}
            >
              <Box sx={{ flex: 1 }}>
                Scan UOM {proList.itemCode}
              </Box>
              <Box 
                sx={{
                  display: 'flex', 
                  alignItems: 'center', // Center the image vertically
                  flexShrink: 0, // Prevent image from shrinking
                  maxWidth: '20%', 
                  maxHeight: '150px', 
                  overflow: 'hidden'
                }}
              > 
               <img
                src={scanUomData?.uom_images?.[0]?.image ? `https://api.baladiexpress.com/${scanUomData.uom_images[0].image}` : '/path/to/default-image.png'}
                alt="UOM Image"
                style={{ width: '100%', maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
              />

              </Box>
            </Box>
          </Typography>


              <Box sx={{ mb: 1  ,display: 'flex', flexDirection: 'column', gap: 1  }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                        Product Name:
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                          {proList?.products_locales?.[0]?.title || 'N/A'}
                        </Typography>

                      </Box>  
                     
              </Box>

              <Box sx={{ mb: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                 <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    UOM Name:
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                    {scanUomData?.uomName}
                  </Typography>
                </Box>
                    </Box>
                    <Box sx={{ mb: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                  Stock Quantity:
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                  {scanUomData.stockQuantity}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                  Relation With Base:
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                  {scanUomData.relationWithBase}
                  </Typography>
                </Box>
              </Box>

            </Box>
          </Grid>

        </Grid>

        <Box sx={{ mt: 1 }}>
          <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold', mb: 1 }}>
            Related UOMs
          </Typography>

          <Grid container spacing={1}>
            {uomList.map((uom) => (
              <Grid item xs={6} sm={4} md={3} key={uom.id}>
                <Box
                  sx={{
                    borderRadius: 2,
                    boxShadow: 1,
                    p: 1,
                    backgroundColor: 'background.paper',
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={`https://api.baladiexpress.com/${uom?.uom_images[0].image}`}
                    alt={`UOM Image ${uom.uomName}`}
                    style={{ maxWidth: '100%', maxHeight: '50px', objectFit: 'contain', marginBottom: '8px' }}
                  />

                  <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    UOM Name:
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                    {uom.uomName}
                  </Typography>

                  <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    Stock Quantity:
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '3rem' , color:'red' }}>
                    {uom.stockQuantity}
                  </Typography>

                  <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    Relation With Base:
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                    {uom.relationWithBase}
                  </Typography>

                  <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    Barcode:
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                    {uom.uom_barcode_relations[0].barcode}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
              </Box></>}
        
               {/* Ledger Log Data Section */}
               <Box sx={{ mt: 2 }}>
  <Typography variant="h6" sx={{ fontSize: '1.25rem', fontWeight: 'bold', mb: 2 }}>
    Ledger Log
  </Typography>

  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1, // Small gap between items
      width: '100%', // Full width
    }}
  >
    {logData ? (
      logData.map((log) => (
        <Box
          key={log.id}
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            p: 2,
            backgroundColor: 'background.paper',
            boxShadow: 1,
            mb: 1,
            display: 'grid',
            gridTemplateColumns: '1fr 2fr', // Two columns layout
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            Document Type:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {log.documentType}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            Product ID:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {log.productId}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            UOM ID:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {log.uomId}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            Quantity Inward:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {log.qty_inward}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            Quantity Outward:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {log.qty_outward}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            Balance:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {log.balance}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            Confirm:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {log.confirm}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            Created By:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {log.user ? log.user.full_name : 'N/A'}
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            Created At:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            {new Date(log.createdAt).toLocaleString()}
          </Typography>
        </Box>
      ))
    ) : (
      <Typography variant="body2" sx={{ fontSize: '0.75rem', color: 'text.secondary' }}>
        No ledger log entries found.
      </Typography>
    )}
  </Box>
</Box>

      </Box>
    )}
  </Box>
</Box>

    </>
  )
 
}
 