import React, { useState, useEffect , useRef } from "react";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Typography, TextField, Grid, Divider , CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import services from "../../../services";
import { Alert , TableContainer  , Table , TableHead , TableRow  , TableCell , TableBody} from "@mui/material";
import {
  changeUserId,
  currentUserId,
} from "../../../redux/reducers/manageUser";
import axios from "axios";
import constant from "../../../constant";
import api from "../../../api";
import { X_API_KEY } from "../../ENUM";
 
export default function BarcodeUpdate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState([]);
  const [errorDownload, setErrorDownload] = useState(false);
  const [logId, setLogId] = useState('');
  const [uomList, setUomList] = useState([]); 
  const [proList, setProList] = useState([]);
  const [barcode, setBarcode] = useState('');


  const [manufactureDate, setManufactureDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');


  const [successFile, setSuccessFile] = useState([]);
  const [successDownload , setSuccessDownload] = useState(false);
  let userId = useSelector(currentUserId);
 
  const formRef = useRef(null);
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const handleDownloadSample = () => {
    window.open(
      `${constant.IMG_BASE_URL}/filetemplate/filetemplate.csv`,
      "_blank"
    );
  };

  const handleManufactorDateChange = (e) => {
    setManufactureDate(e.target.value);
  };

  const handleExpiryDateChange = (e) => {
    setExpiryDate(e.target.value);
  };
  const getUserData = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_USER_DISCRIPTIONS(userId);
    if (response?.data?.ack == 1) {
      setFirstName(response?.data?.picker?.first_name);
      setLastName(response?.data?.picker?.last_name);
      setEmail(response?.data?.picker?.email);
      setRole(response?.data?.picker?.role);
    }
    dispatch(changeLoader(false));
  };
 
  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, []);

 
  
 
  
    const convertJsonToCsv = (jsonData) => {
      const header = Object.keys(jsonData[0]).join(",");
      const rows = jsonData.map((data) => Object.values(data).join(","));
      return [header, ...rows].join("\n");
    };
 
  
    const handleBarcodeChange = (event) => {
      setBarcode(event.target.value);
    };
  
  
  const handledatesubmt = async (event) => {
    
    event.preventDefault();
    setIsLoading(true);
    try {
      
      const dataobj = {
        exprydate: expiryDate,
        manufacture: manufactureDate
      };
      
      const headers = {
        "x-api-key": X_API_KEY
      };
      
      
        const response = await axios.post(
          constant.BASE_URL + api.Bulk.update_date + barcode,
          dataobj,
          { headers }
        );
      
        const { success, message, data } = response.data;
       
      if (success) {
         console.log(message);
        // setManufactureDate('');
        // setExpiryDate('');
          setAlertMsg(message);
        setSeverity("success");
        setShowAlert(true);
        disableAlert()
        setIsLoading(false)
      } else {
        console.log(message);
       
        setAlertMsg(message);
        setSeverity("warning");
        setShowAlert(true);
        disableAlert()
        setIsLoading(false)
      }
    } catch (error) {
          setAlertMsg(error.message);
        setSeverity("warning");
        setShowAlert(true);
        disableAlert()
        setIsLoading(false)
    }
 
  };
    const handleBarcodeSubmit = async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        
        const response = await axios.get( constant.BASE_URL + api.Bulk.getAllbarcode+barcode , {
          headers: {
            "x-api-key": X_API_KEY,
          },
        } );
        const { success, message, data  , products, LogId} = response.data;
        if (success) {
          setUomList(data);

          setManufactureDate( data[0].product_manufacture);
          setExpiryDate( data[0].product_expiry);
         
           
          console.log(products);
          setProList(products);
          setLogId(LogId);
          setIsLoading(false);
        } else {
          console.log(message);
          setUomList([]);
          setAlertMsg(message);
          setSeverity("warning");
          setShowAlert(true);
          disableAlert()
          setIsLoading(false);
        }
      } catch (error) {
        console.log('Error:', error);
        setAlertMsg(error.message);
        setSeverity("warning");
        setShowAlert(true);
        disableAlert()
        setUomList([]);
        setIsLoading(false);
      }
    };
    const [editingRow, setEditingRow] = useState(null);

    const handleEdit = (rowId) => {
      setEditingRow(rowId);
    };
    
  const handlethressholdChange = (rowId, value) => { 
    if (value == '') {
      value = 0;
    }
    const updatedUomList = uomList.map(uom => {
      if (uom.id === rowId) {
        // Update the threshold value for the matching object
        return { ...uom, threshold: parseInt(value) };
      } else {
        return uom; // Return unchanged objects
      }
    });
    console.log("updatedUomList", updatedUomList);
    // Set the updated array back to state (assuming you're using React's useState)
    setUomList(updatedUomList);
  }
  const handleStockQuantityChange = (rowId, value) => {
    if (value == '') {
      value = 0;
    }
      setUomList((prevData) => {
        if (!prevData || prevData.length === 0) {
          return prevData;
        }
    
        let calculatedValue = 0
        let updatedData = prevData.map((uom) => {
          const updatedUom = {
            ...uom,
            stockQuantity: 0, // Set the stock quantity to 0 for all UOMs
          };
          return updatedUom;
        });

          updatedData = prevData.map((uom) => {
          if (uom.id === rowId) {
              calculatedValue = value * uom.relationWithBase;
            const updatedUom = {
              ...uom,
              stockQuantity: parseInt(value),
            };
            return updatedUom;
          }
          return uom;
        });
    
        const updatedUomList = updatedData.map((uomItem) => {
          if (uomItem.id !== rowId) {
            const updatedQuantity = parseInt(calculatedValue / uomItem.relationWithBase);
            return { ...uomItem, stockQuantity: updatedQuantity };
          }
          return uomItem;
        });
    
        return updatedUomList;
      });
    };
    
    
    
    const handleSave = () => {
      // Create a new array with the desired properties
      const newData = uomList.map((uom) => ({
        id: uom.id,
        stockQuantity: uom.stockQuantity,
        status: uom.stockQuantity > 0 ? 'active' : 'inactive',
        threshold : uom.threshold 
      }));
    
      // Create the formData object to send the newData
      
      // Make the API request using Axios
      axios
        .post(constant.BASE_URL + api.Bulk.BarcodeUpdate, {barcodeData:newData , LogId : logId}, {
          headers: {
            "x-api-key": X_API_KEY, // Replace X_API_KEY with the actual value
          },
        })
        .then((response) => {
          console.log(response.data);
          response = response.data;
          console.log("status", response.status);
          // setIsLoading(false); // Set loading state to false on response
          if (response.status == 'fail') {
          
        
            setSeverity('warning');
            setAlertMsg('UOM data not update.');
             setShowAlert(true);
            setLogId(null);
            setEditingRow(null);
           
            disableAlert()
          } else if (response.status == 'success') {
            
               setSeverity('success');
            setAlertMsg('UOM data saved successfully.');
            setShowAlert(true);
            setEditingRow(null);
            disableAlert();
            setUomList([]);
          }
        })
        .catch((error) => {
          // Handle error
          if (error.response && error.response.data && error.response.data.status === false) {
            setShowAlert(true);
            setAlertMsg(error.response.data.message);
            setSeverity("error");
            disableAlert()
          }
          // setIsLoading(false); // Set loading state to false on response
        });
    
      // Clear the editing state and display a success message
    
    };
   
    return (
      <>
        <Box className="dashboard-parent">
          <Header />
          <Aside />
          <Box className="main-wrapper">
             <Box className="white-box-20-30">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
           
              <form style={{
                borderRadius: '10px',
                boxShadow: '1px 0px 5px -2px',
                padding: '30px',
                margin:'10px'
              }} onSubmit={handleBarcodeSubmit}>
                   <Typography variant="h5" gutterBottom>
                Barcode Form
              </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      type="text"
                      id="barcode"
                      label="Barcode"
                      value={barcode}
                      onChange={handleBarcodeChange}
                    />
                  </Grid>
                </Grid>
                 
                <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={isLoading || !barcode}>
                  Submit Barcode
                </Button>

              </form>
              {uomList.length > 0 && (


            
              <Box style={{
                borderRadius: '10px',
                boxShadow: '1px 0px 5px -2px',
                padding: '30px',
                margin:'10px'
              }} >
              {uomList.length > 0  && (
                 <form onSubmit={handledatesubmt}>
                <Box className="white-box-20-30">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="date"
                        id="manufactorDate" // Use a unique ID for each input
                        label="Manufacture Date"
                        value={manufactureDate} // Use a specific state variable for each input
                        onChange={handleManufactorDateChange} // Use a specific handler for each input
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="date"
                        id="expiryDate" // Use a unique ID for each input
                        label="Expiry Date"
                        value={expiryDate} // Use a specific state variable for each input
                        onChange={handleExpiryDateChange} // Use a specific handler for each input
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                    Save
                  </Button>
                  </Box>
                  </form>
              )}

              {isLoading && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <CircularProgress size={24} />
                    </div>
                  )}
              <Box className="white-box-20-30" >
              {uomList.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                          <TableCell>UOM Name</TableCell>
                          <TableCell>Product ItemCode</TableCell>
                          <TableCell>Product Name</TableCell>
                      <TableCell>Relation With Base</TableCell>
                      <TableCell>Barcode</TableCell>
                      <TableCell>Stock Quantity</TableCell>
                      
                         
                          <TableCell>Threshold</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uomList.map((uom) => (
                      <TableRow key={uom.id}>
                        <TableCell>{uom.uomName}</TableCell>
                          <TableCell>{proList.itemCode}</TableCell>
                         <TableCell>{proList.slug}</TableCell>
                        <TableCell>{uom.relationWithBase}</TableCell>
                        
                        <TableCell>{uom.uom_barcode_relations[0].barcode}</TableCell>
                        {/* <TableCell>
                          {editingRow === uom.id ? (
                         <Box border={1} borderRadius={2} p={0}>
                         <TextField
                           value={uom.stockQuantity}
                           onChange={(e) => handleStockQuantityChange(uom.id, e.target.value)}
                           variant="outlined" // Add an outline border
                           size="small" // Reduce the size of the input field
                           InputProps={{
                             style: {
                               cursor: "text", // Set the cursor to text
                             },
                           }}
                         />
                       </Box>
                          ) : (
                            uom.stockQuantity
                          )}
                        </TableCell>
                        <TableCell>
                          {editingRow === uom.id ? (
                         <Box border={1} borderRadius={2} p={0}>
                         <TextField
                           value={uom.threshold}
                           onChange={(e) => handlethressholdChange(uom.id, e.target.value)}
                           variant="outlined" // Add an outline border
                           size="small" // Reduce the size of the input field
                           InputProps={{
                             style: {
                               cursor: "text", // Set the cursor to text
                             },
                           }}
                         />
                       </Box>
                          ) : (
                            uom.threshold
                          )}
                        </TableCell>
                      
                        
                        <TableCell>
                          {uom.relationWithBase === 1 ? (
                            editingRow === uom.id ? (
                              <Button variant="contained" color="success" onClick={() => handleSave(uom.id)}>
                                Save
                              </Button>
                            ) : (
                              <Button variant="contained" onClick={() => handleEdit(uom.id)}>
                                Edit
                              </Button>
                            )
                          ) : null}
                        </TableCell> */}
                    <TableCell>
                        {editingRow === uom.id ? (
                          <>
                            {uom.relationWithBase === 1 ? (
                              <Box border={1} borderRadius={2} p={0}>
                                <TextField
                                  value={uom.stockQuantity}
                                  onChange={(e) => handleStockQuantityChange(uom.id, e.target.value)}
                                  variant="outlined" // Add an outline border
                                  size="small" // Reduce the size of the input field
                                  InputProps={{
                                    style: {
                                      cursor: "text", // Set the cursor to text
                                    },
                                  }}
                                />
                              </Box>
                            ) : (
                              uom.stockQuantity
                            )}
                          </>
                        ) : (
                          uom.stockQuantity
                        )}
                      </TableCell>


                    <TableCell>
                      {editingRow === uom.id ? (
                        <Box border={1} borderRadius={2} p={0}>
                          <TextField
                            value={uom.threshold}
                            onChange={(e) => handlethressholdChange(uom.id, e.target.value)}
                            variant="outlined" // Add an outline border
                            size="small" // Reduce the size of the input field
                            InputProps={{
                              style: {
                                cursor: "text", // Set the cursor to text
                              },
                            }}
                          />
                        </Box>
                      ) : (
                        uom.threshold
                      )}
                    </TableCell>

                    <TableCell>
                      
                          {
                            proList.baseUomId == uom.id ? (
                             editingRow === uom.id ? (
                                                <Button variant="contained" color="success" onClick={() => handleSave(uom.id)}>
                                                  Save
                                                </Button>
                                              ) : (
                                                <Button variant="contained" onClick={() => handleEdit(uom.id)}>
                                                  Edit
                                                </Button>
                              )
                            ) : null
                          
                          }
                    
                    </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                  </TableContainer>
                    )}
              </Box>
                </Box>
              
              )}
             </Box>
          </Box>
        </Box>
      </>
    );
    
}
